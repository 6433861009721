import tw from "tailwind-styled-components";
import InputMask from "react-input-mask";
import styled from "styled-components";

export const HeaderSpacer = styled.div`
  width: 100%;
  height: 75px;
  background-color: transparent;
`;

export const Form = tw.form`
  overflow-x-hidden
`;

export const ArticleTitle = tw.article`
  w-full
  flex 
  flex-col
  justify-center
  items-center
  bg-pensamental-dark
  px-10
  pb-4 md:pb-4
  pt-2 md:pt-2
`;

export const ContentTitle = tw.div`
  max-w-screen-md
`;

export const Title = tw.h1`
  font-baloo
  text-4xl
  text-white
  text-center
`;

export const ContentInfo = tw.div`
  text-center
  w-full
  max-w-screen-md
  flex
  flex-col
`;

export const ContentInfoCTA = tw.div`
  text-center
  w-full
  max-w-screen-md
  flex
  mt-2

  ${(props) => (props.$centered ? "justify-center" : "justify-between")}
`;

export const SubInfo = tw.h2`
  mt-2
  font-baloo
  text-4xl
  text-blue-pensamental-dark
  text-left
  mb-4
  text-center
`;

export const SubInfoDescription = tw.p`
  font-comfortaa
  text-md
  text-gray-700
  text-left
  mb-4
`;

export const ArticleInfo = tw.article`
  min-h-formSM
  md:min-h-formMD 
  lg:min-h-formLG
  w-full
  flex 
  flex-col
  justify-start
  items-center
  bg-white
  p-6
`;

export const Label = tw.label`
  font-comfortaa
  text-sm md:text-md
  text-gray-700
  mb-4
`;

export const Input = tw.input`
  focus:ring-pensamental-dark 
  focus:border-pensamental-dark 
  block
  w-full
  pl-4
  pr-4
  text-xs
  rounded-md
  placeholder-gray-500 placeholder-opacity-25
  
  ${(props) => (props.$hasError ? "border-red-500" : "border-gray-300")}
`;

export const InputWithMask = tw(InputMask)`
  focus:ring-pensamental-dark 
  focus:border-pensamental-dark 
  block
  w-full
  pl-4
  pr-4
  text-xs
  rounded-md
  placeholder-gray-500 placeholder-opacity-25
  
  ${(props) => (props.$hasError ? "border-red-500" : "border-gray-300")}
`;

export const Select = tw.select`
  focus:ring-pensamental-dark 
  focus:border-pensamental-dark 
  block
  w-full
  pl-4
  pr-4
  text-xs
  rounded-md
  
  ${(props) => (props.$hasError ? "border-red-500" : "border-gray-300")}
`;

export const SubmitForm = tw.button`
  outline-none
  no-underline 
  inline-flex 
  items-center 
  justify-center 
  px-12
  py-3
  font-baloo 
  transition
  duration-300
  ease-in-out 
  transform 
  hover:scale-110
  focus:ring-2 
  focus:ring-blue-pensamental-dark
  w-2/5 sm:w-1/5
  rounded-md
  ${(props) => (props.$disabled ? "bg-gray-300" : "bg-blue-pensamental-darkest")}
  ${(props) => (props.$disabled ? "cursor-not-allowed" : "cursor-pointer")}
  ${(props) => (props.$disabled ? "text-gray-400" : "text-white")}
`;
